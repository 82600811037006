import { mapHelper } from '@/utils/utils';

// 工单状态
const workOrderStatus = [
  { text: '待审核', value: 1 },
  { text: '服务中', value: 0 },
  { text: '已完结', value: 2 },
  { text: '已取消', value: 99 }
  // 以下是之前的
  // { label: "服务中", value: 0 },
  // { label: "已服务", value: 1 },
  // { label: "已完成" value: 2 },
];
const { map: workOrderStatusMap, setOps: setWorkOrderStatusOps } = mapHelper.setMap(workOrderStatus);

export { workOrderStatus, workOrderStatusMap, setWorkOrderStatusOps };
